<template>
  <lf-card class="max-w-screen-md">
    <loader :is-loading="isSubmitting" />
    <form
      class="flex flex-col space-y-8 divide-y py-8"
      @submit.prevent="submit"
    >
      <div>
        <lf-switch
          name="data.can_manage_docusign"
          class="space-y-2 switch-container"
          v-model="initialValues.data.can_manage_docusign"
        >
          <lf-h3>
            {{ $t("ORGANIZATION.ENABLE_CONTRACT_BUILDER") }}
          </lf-h3>
          <span>
            {{ $t("ORGANIZATION.ENABLE_CONTRACT_BUILDER_SUBTITLE") }}
          </span>
        </lf-switch>
      </div>
      <div class="flex justify-end items-center min-w-full pt-5 pr-5">
        <primary-button
          type="submit"
          :disabled="isSubmitting"
          data-cy="contract-builder-submit"
        >
          {{ $t("ORGANIZATION.API_CONFIG.SAVE_CHANGES") }}
        </primary-button>
      </div>
    </form>
  </lf-card>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import { useClients } from "@/hooks/clients";

const { activeClient } = useClients();

const initialValues = reactive({
  data: {
    can_manage_docusign: !!activeClient.value?.can_manage_docusign
  }
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues
});

const submit = handleSubmit(async (values, actions) => {
  await dispatchAction(values, actions, "clients/updateClient");
});
</script>
<style scoped>
:deep(.switch-container .switch-content) {
  @apply space-x-8;
}
</style>
